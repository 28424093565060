import {basicSetup} from "codemirror"
import {EditorState, Compartment} from "@codemirror/state"
import {EditorView, keymap} from "@codemirror/view"
import {defaultKeymap} from "@codemirror/commands"
import { oneDark } from "@codemirror/theme-one-dark";
import { javascript } from "@codemirror/lang-javascript";
import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";

const refreshTimeout = 500;
let lastCodeChange = 0;
let refreshTimer = null;
let editor = null;
let changingTabs = false;

const tabs = [
  {
      active: true,
      name: 'HTML',
      language: html,
      code: '\n\n\n\n\n\n'
  },
  {
      active: false,
      name: 'CSS',
      language: css,
      code: '\n\n\n\n\n\n'
  },
  {
      active: false,
      name: 'JavaScript',
      language: javascript,
      code: '\n\n\n\n\n\n'
  }
];

function handleCodeChange(update){
  
  if (!update.docChanged) {
    return;
  }

  if(changingTabs){
      return;
  }

  if (refreshTimer) {
    clearTimeout(refreshTimer);
  }
  const currentContent = update.state.doc.toString();

  let activeTab = tabs.find((tab) => tab.active);
  activeTab.code = currentContent;

  refreshTimer = setTimeout(() => {
      if (lastCodeChange + refreshTimeout < Date.now()) {
          renderPreview();
      }
  }, refreshTimeout);

  lastCodeChange = Date.now();
}

let language = new Compartment;

let startState = EditorState.create({
  //doc: "",
  extensions: [
    basicSetup,
    language.of(html()),
    keymap.of(defaultKeymap),
    oneDark,
    EditorView.updateListener.of(handleCodeChange)
    //oneDarkTheme
  ]
});

let view = new EditorView({
  state: startState,
  parent: document.getElementById("editor")
});

function generateCode(){
    
    let htmlCode = tabs[0].code.trim();
    let cssCode = tabs[1].code.trim();
    let jsCode = tabs[2].code.trim();

    let fullCode = `
        <!DOCTYPE html>    
        <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                /* Your CSS code */
                ${cssCode}
                <\/style>
            </head>
            <body>
              <!-- Your HTML code -->
              ${htmlCode}
                
              <!-- Your JavaScript code -->
              <script>
                  ${jsCode}
              <\/script>
            </body>
        </html>
    `;

    return fullCode;
}

function togglePreviewPlaceholder(){
    
    let placeholder = document.getElementById('placeholder');
    let iframe = document.getElementById('preview-iframe');

    // Loop through tabs and check if code is blank
    let showPlaceholder = true;
    tabs.forEach((tab) => {
      if(tab.code.trim() !== ''){
            showPlaceholder = false;
        }
    });

    if(showPlaceholder){
        placeholder.style.display = 'flex';
    } else {
        placeholder.style.display = 'none';
    }
}

function renderPreview(){

    togglePreviewPlaceholder();

    let preview = generateCode();

    let iframe = document.getElementById('preview-iframe');
    let targetDoc = iframe.contentWindow.document;

    targetDoc.open();
    targetDoc.write(preview);
    targetDoc.close();
}

function init(){
    
    togglePreviewPlaceholder();

    tabs.forEach((tab, index) => {

        let editorTab = document.createElement('li');
        editorTab.classList.add('tab');
        editorTab.innerHTML = tab.name;
        editorTab.setAttribute('data-index', index);

        editorTab.addEventListener('click', () => {
            
            changingTabs = true;
            
            document.querySelectorAll('.tab').forEach((tab) => {
                tab.classList.remove('active');
            });

            //editor.setValue(tabs[index].code);

            // Set editor language
            //monaco.editor.setModelLanguage(editor.getModel(), tabs[index].language);

            view.dispatch({
              changes: {from: 0, to: view.state.doc.length, insert: tabs[index].code},
              effects: language.reconfigure(tabs[index].language())
            });

            editorTab.classList.add('active');

            tabs.forEach((tab) => {
                tab.active = false;
            });

            tabs[index].active = true;

            changingTabs = false;

            window.sa_event('tab_changed');
        });

        document.querySelector('.tabs').appendChild(editorTab);
    });

    // Default click on first tab
    document.querySelector('.tab').click();
}

window.download = function(){
  
    window.sa_event('code_export');

    let preview = generateCode();
    let blob = new Blob([preview], {type: 'text/html'});
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'code.html';
    a.click();
    a.remove();
}

init();
